export default function () {
    // Form modal
    const formModal = document.getElementById("form-modal");
    const mobile = window.matchMedia("(max-width: 899px)");

    if (formModal) {
        const openTriggers = Array.from(document.querySelectorAll("[data-modal-open]"));
        const closeTriggers = Array.from(document.querySelectorAll("[data-modal-close]"));
        var autoOpen;

        // Bind open triggers
        openTriggers.forEach((trigger) => {
            trigger.addEventListener("click", openModal);
        });

        // Bind close triggers
        closeTriggers.forEach((trigger) => {
            trigger.addEventListener("click", closeModal);
        });

        function openModal(e) {
            e && e.preventDefault();
            formModal.classList.add("form-modal--open");
            document.body.classList.add("modal-open");
            if( autoOpen ) {
                clearTimeout(autoOpen);
            }
        }

        function closeModal(e) {
            e && e.preventDefault();
            formModal.classList.remove("form-modal--open");
            document.body.classList.remove("modal-open");
            window.sessionStorage.setItem("form-modal", "opened");
        }

        // default open
        if (!mobile.matches) {
            var tmp = window.sessionStorage.getItem("form-modal");
            if (tmp !== "opened") {
                autoOpen = setTimeout(function () {
                    formModal.classList.add("form-modal--open");
                }, 25000);
            }
        }
    }
};
