const mainNav = document.querySelector(".header-main");
const mainNavToggle = mainNav.querySelector(".toggler");
const scrolled = "header-main--scrolled";
const homeNav = "header-main--home";
let lastScroll = 0;

function mobileToggle() {
    if (mainNavToggle) {
        mainNavToggle.addEventListener("click", () => {
            document.body.classList.toggle("mobile-nav-open");
        });
    }
}

function scrollAway() {
    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        const treshold = mainNav.scrollHeight;

        if (currentScroll == 0) return;

        if (currentScroll <= (document.body.scrollHeight - window.innerHeight) && currentScroll > lastScroll && currentScroll > treshold && !mainNav.classList.contains(scrolled)) {
            // down
            mainNav.classList.add(scrolled);
        }  else if ( mainNav.classList.contains(homeNav) && currentScroll > 0 && currentScroll < (160 + treshold)) {
            // down
            mainNav.classList.add(scrolled);
        }else if (currentScroll > 0 && currentScroll < lastScroll && mainNav.classList.contains(scrolled)) {
            // up
            mainNav.classList.remove(scrolled);
        }

        lastScroll = currentScroll;
    });
}

export default function() {
    scrollAway();
    mobileToggle();
}
