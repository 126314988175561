import 'promise-polyfill/src/polyfill';
import objectFitImages from 'object-fit-images';
import header from './header';
import poiMap from './poi-map';
import macy from './macy';
import forms from './forms';
import photoSwiper from './photo-swiper';
import lightbox from './lightbox';
import buildingHeaderSwiper from './building-header-swiper';
import homeFeaturedApartmentsSwiper from './home-featured-apartments-swiper';
import formModal  from './form-modal';

objectFitImages();
poiMap();
header();
macy();
forms();
formModal();
photoSwiper();
buildingHeaderSwiper();
homeFeaturedApartmentsSwiper();
lightbox();