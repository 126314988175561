import Macy from "macy";

export default function() {
    const macyElements = [...document.querySelectorAll(".macy-container")];

    macyElements.forEach(macyElement => {
        let macyInstance = Macy({
            container: macyElement,
            trueOrder: true,
            waitForImages: true,
            margin: {
                y: 24
            },
            columns: 1,
            mobileFirst: true,
            breakAt: {
                768: {
                    margin: {
                        x: 24,
                        y: 40
                    },
                    columns: 2
                },
                1200: {
                    margin: {
                        x: 24,
                        y: 80
                    },
                    columns: 3
                }
            }
        });

        macyInstance.runOnImageLoad(() => macyInstance.recalculate(true), true);
    });
}
