import Swiper from "swiper";

const bhsElement = document.querySelector(".building-header .swiper-container");

export default function() {
    if (bhsElement) {
        let bhs = new Swiper(bhsElement, {
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });
    }
}
