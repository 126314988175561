import Swiper from "swiper";

const hfaElements = document.querySelectorAll(".home-featured-apartments .swiper-container");

function positionCarousselArrow(swiperInstance) {
    const caroussel = swiperInstance.$el[0];
    const firstImage = caroussel.querySelector(".apartment-card__image img");
    const arrow = caroussel.querySelector('.swiper-button-next > span');

    if (firstImage && arrow) {
        arrow.style.top = `${firstImage.scrollHeight / 2 - arrow.scrollHeight / 2}px`;
    }
}

function initFeaturedApartments() {
    hfaElements.forEach(hfaElement => {
        if (hfaElement) {
            let hfa = new Swiper(hfaElement, {
                loop: true,
                slidesPerView: 1,
                loopedSlides: 4,
                spaceBetween: 20,
                allowSlidePrev: false,
                navigation: {
                    nextEl: ".swiper-button-next"
                },
                breakpointsInverse: true,
                breakpoints: {
                    900: {
                        slidesPerView: 2,
                        spaceBetween: 60
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 60
                    }
                },
                on: {
                    init: function() {
                        positionCarousselArrow(this);
                    },
                    resize: function() {
                        positionCarousselArrow(this);
                    }
                }
            });
        }
    });
}
export default function() {
    initFeaturedApartments();
}
