import Swiper from 'swiper/js/swiper';

export default function() {

    if( document.getElementsByClassName('photo-swiper-middle').length > 0 
        && document.getElementsByClassName('photo-swiper-prev').length > 0 
        && document.getElementsByClassName('photo-swiper-next').length > 0
        // && window.innerWidth > 599
        ) {

        // const swiperSlideCount = document.querySelectorAll(".swiper-container.photo-swiper .swiper-slide").length;

        var photoSwiper = new Swiper('.photo-swiper-middle', {
            loop: true,
            speed: 600,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            autoplay: {
                delay: 5000,
            }
        });

        var photoSwiperPrev = new Swiper('.photo-swiper-prev', {
            loop: true,
            allowTouchMove: false,
            initialSlide: -1,
            effect: 'fade',
            on: {
                click: function(e) {
                    e.preventDefault();
                    photoSwiper.slidePrev();
                }
            }
        });

        var photoSwiperNext = new Swiper('.photo-swiper-next', {
            loop: true,
            allowTouchMove: false,
            initialSlide: 1,
            effect: 'fade',
            on: {
                click: function(e) {
                    e.preventDefault();
                    photoSwiper.slideNext();
                }
            }
        });

        photoSwiper.on('slideChange', function () {
            photoSwiperNext.slideTo(photoSwiper.realIndex + 2, 600);
            photoSwiperPrev.slideTo(photoSwiper.realIndex, 600);
        });

    }

}