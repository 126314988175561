export default function() {

    if(!document.getElementById("pois-map")) return;

    var map = new mapboxgl.Map({
        container: "pois-map",
        style: "mapbox://styles/mapbox/light-v10",
        center: [3.7410471, 51.0410495],
        zoom: 12.0
    });

    map.on("load", function () {
        
        map.loadImage("/assets/keizerpoort/img/map-logo-keizerpoort.png", function (error, image) {
            if (error) throw error;
            map.addImage("keizerpoort-logo", image);
            map.addLayer({
                    id: "markers",
                    type: "symbol",
                    source: {
                        type: "geojson",
                        data: {
                            type: "FeatureCollection",
                            features: [{ 
                                "type": "Feature", 
                                "geometry": { 
                                    "type": "Point", 
                                    "coordinates": [3.7410471, 51.0410495] 
                                } 
                            }]
                        }
                    },
                    layout: {
                        "icon-image": "keizerpoort-logo",
                        "icon-size": 0.25
                    }
            });
        });

        if( poiJson !== undefined ) {
            
            map.addSource("pois", {
                type: "geojson",
                data: poiJson,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50
            });
            
            map.addLayer({
                id: "clusters",
                type: "circle",
                source: "pois",
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": "#F68700",
                    "circle-stroke-color": "#FFDBAE",
                    "circle-stroke-width": 1,
                    "circle-radius": [
                        "step",
                        ["get", "point_count"],
                        12,
                        5, 18,
                        10, 26
                    ],
                }
            });
        
            map.addLayer({
                id: "cluster-count",
                type: "symbol",
                source: "pois",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count_abbreviated}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12                    
                },
                paint: {
                    "text-color": "#FFFFFF"
                }
            });
            
            map.addLayer({
                id: "unclustered-point",
                type: "circle",
                source: "pois",
                filter: ["!", ["has", "point_count"]],
                paint: {
                    "circle-color": "#F68700",
                    "circle-radius": 5,
                    "circle-stroke-color": "#FFDBAE",
                    "circle-stroke-width": 1
                }
            });
            
            map.on('click', 'clusters', function (e) {
                var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
                var clusterId = features[0].properties.cluster_id;
                map.getSource('pois').getClusterExpansionZoom(clusterId, function (err, zoom) {
                    if (err)
                        return;
                    
                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                });
            });

            map.on('click', 'unclustered-point', function (e) {
                var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties.description;
                
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                
                new mapboxgl.Popup({
                    "anchor": "top-left",
                    "maxWidth": "200px",
                    "className": "poi-infowindow",
                    "closeButton": false,
                    // "offset": Math.round((e.lngLat.lng - coordinates[0]) / 360) * 360
                })
                    .setLngLat(coordinates)
                    .setHTML(description)
                    .addTo(map);
            });
        
            map.on('mouseenter', 'clusters', function () {
                map.getCanvas().style.cursor = 'pointer';
            });
    
            map.on('mouseleave', 'clusters', function () {
                map.getCanvas().style.cursor = '';
            });
        }
        
    });

    map.addControl(new mapboxgl.NavigationControl());
    map.scrollZoom.disable();
    
}
