export default function() {
    const forms = [...document.querySelectorAll(".form")];
    forms.forEach(form => {
        form.addEventListener("freeform-ready", function(event) {
            const freeform = event.target.freeform;

            form.addEventListener("submit", function() {
                // form.querySelector(".form__fieldset").disabled = true;
            });
            
            freeform.addOnFailedAjaxSubmit((event, form, response) => {
                // Do something on a failed ajax submit
                // form.querySelector(".form__fieldset").disabled = false;
            });

            freeform.setOption("renderSuccess", function() {
                let form = this.form;
                let parent = form.parentNode;
                let msg = parent.querySelector(".form__success");
                msg.style.display = "block";
                parent.removeChild(form);

                if (typeof ga !== "undefined") { 
                    if( form.dataset.seoEvent ) {
                        ga('send', {
                            hitType: 'event',
                            eventCategory: 'lead',
                            eventAction: form.dataset.seoEvent,
                            eventLabel: 'sent'
                        });
                    }
                }
            });
        });
    });
}
